<script lang="ts" setup>
import type { HTMLAttributes } from "vue";
import { buttonVariants } from "./button-variants";

export interface Props {
  type?: "button" | "submit";
  label: string;
  variant?: "filled" | "filledTonal" | "outlined" | "ghost" | "destructive";
  isLoading?: boolean;
  disabled?: boolean;
  icon?: string;
  class?: HTMLAttributes["class"];
}

const props = withDefaults(defineProps<Props>(), {
  type: "button",
  variant: "filled",
  isLoading: false,
  disabled: false,
  icon: undefined,
  class: "",
});
</script>

<template>
  <button
    :type="props.type"
    :disabled="disabled || isLoading"
    :class="
      cn(
        buttonVariants({
          variant: props.variant,
          disabled: props.disabled,
        }),
        'relative',
        props.icon && props.variant !== 'ghost' && 'pl-4',
        props.class,
      )
    "
  >
    <Icon
      v-if="props.icon"
      :name="props.icon"
      :class="cn('size-5', isLoading && 'invisible')"
    />

    <span :class="!isLoading ? 'visible' : 'invisible'">{{ label }}</span>

    <Icon
      name="svg-spinners:ring-resize"
      :class="
        cn(
          'absolute left-1/2 top-1/2 size-5 -translate-x-1/2 -translate-y-1/2',
          isLoading ? 'visible' : 'invisible',
        )
      "
    />
  </button>
</template>
